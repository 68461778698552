/**
 * Nestable
 */
.dd {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
    background: $table-border-color;
}

.dd-list {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
}

.dd-list .dd-list {
    padding-left: 30px;
}

.dd-collapsed .dd-list {
    display: none;
}

.dd-item,
.dd-empty,
.dd-placeholder {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    min-height: 48px;
}

.dd-handle {
    display: block;
    height: 58px;
    padding: $table-cell-padding;
    color: $text-color;
    text-decoration: none;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    position: absolute;
    margin: 0;
    left: 0;
    top: 0;
    cursor: pointer;
    width: 30px;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: middle;
}
.dd-handle:before {
    content: '\f0c9';
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
    text-align: center;
    text-indent: 0;
    color: $gray-lighter;
}
.dd3-handle:hover:before {
    color: $gray-light;
}

[type=button] ~ .dd3-content {
    padding-left: 70px;
}

.dd-item > button {
    color: $gray-light;
    position: absolute;
    top: 18px;
    left: 40px;
    display: block;
    cursor: pointer;
    width: 25px;
    height: 20px;
    text-indent: -9999px;
    white-space: nowrap;
    overflow: hidden;
    border: 0;
    background: transparent;
    line-height: 1;
    text-align: center;
}

.dd-item > button:before {
    color: $gray-light;
    content: '\f0da';
    font-family: FontAwesome;
    display: block;
    position: absolute;
    width: 100%;
    text-align: center;
    text-indent: 0;
    left: 0;
}

.dd-item > button[data-action="collapse"]:before {
    content: '\f0d7';
}

.dd-placeholder,
.dd-empty {
    margin: 0;
    padding: 0;
    min-height: 58px;
    background: $body-bg;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.dd-empty {
    min-height: 100px;
    background-color: $panel-default-heading-bg;
}

.dd-dragel {
    position: absolute;
    pointer-events: none;
    z-index: 9999;
    background: $panel-default-heading-bg;
}

.dd-dragel > .dd-item .dd-handle {
    margin-top: 0;
}

.dd-dragel .dd-handle {
}

/**
 * Nestable Draggable Handles
 */
.dd3-content {
    -webkit-transition:background 1s;
    -moz-transition:background 1s;
    -o-transition:background 1s;
    transition:background 1s;

    display: block;
    height: 58px;
    padding: $table-cell-padding;
    padding-left: 40px;
    text-decoration: none;
    border-bottom: 1px solid $table-border-color;
    box-sizing: border-box;
    vertical-align: middle;
    background: #fff;
}

.dd3-content.bg-success {
    background: $alert-success-bg;
}

.dd3-content:hover {
    background: $table-bg-hover;
}

.dd-dragel > .dd3-item > .dd3-content {
    margin: 0;
}

.dd3-item > button {
    margin-left: 30px;
}

.dd3-handle:hover {
    color: $gray-light;
}

.dd-actions {
    width: 32px;
    padding-left: 0;
    float: right;
}

.dd-actions .dropdown {
    position: absolute;
    right: 8px;
    top: 12px;
}