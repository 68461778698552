.flex {
    display: -ms-flexbox;
    display: flex;
}

.justify-content-space-between {
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.align-items-center {
    -ms-flex-align: center;
    align-items: center;
}

