
// Body
$body-bg: #f0f0f1 !default;

// Borders
$laravel-border-color: none;
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;


//
// Variables
// --------------------------------------------------


//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$gray-base:              #000 !default;
$gray-darker:            lighten($gray-base, 13.5%) !default; // #222
$gray-dark:              lighten($gray-base, 20%) !default;   // #333
$gray:                   lighten($gray-base, 33.5%) !default; // #555
$gray-light:             lighten($gray-base, 46.7%) !default; // #777
$gray-lighter:           lighten($gray-base, 93.5%) !default; // #eee

// Brands
$brand-logo: #cb2d3e;
$brand-primary: #1DB25B;
$brand-info: #8eb4cb;
$brand-success: #1DB25B;
$brand-warning: #cbb956;
$brand-danger: #bf5329;

// Typography
$font-family-sans-serif: "Libre Franklin", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #343942;

// Buttons
$btn-default-color: $text-color;


//== Components
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

$padding-base-vertical:     6px !default;
$padding-base-horizontal:   12px !default;

$padding-large-vertical:    10px !default;
$padding-large-horizontal:  16px !default;

$padding-small-vertical:    5px !default;
$padding-small-horizontal:  10px !default;

$padding-xs-vertical:       1px !default;
$padding-xs-horizontal:     5px !default;

$line-height-large:         1.3333333 !default; // extra decimals for Win 8.1 Chrome
$line-height-small:         1.5 !default;

$border-radius-base:        4px !default;
$border-radius-large:       6px !default;
$border-radius-small:       3px !default;

//** Global color for active items (e.g., navs or dropdowns).
$component-active-color:    #fff !default;
//** Global background color for active items (e.g., navs or dropdowns).
$component-active-bg:       $brand-primary !default;

//** Width of the `border` for generating carets that indicate dropdowns.
$caret-width-base:          4px !default;
//** Carets increase slightly in size for larger components.
$caret-width-large:         5px !default;

//== Forms
//
//##

//** `<input>` background color
$input-bg:                       #fff !default;
//** `<input disabled>` background color
$input-bg-disabled:              $gray-lighter !default;

//** Text color for `<input>`s
$input-color:                    $gray !default;
//** `<input>` border color
$input-border:                   lighten($gray-light, 30%) !default;

// TODO: Rename `$input-border-radius` to `$input-border-radius-base` in v4
//** Default `.form-control` border radius
// This has no effect on `<select>`s in some browsers, due to the limited stylability of `<select>`s in CSS.
$input-border-radius:            $border-radius-base !default;
//** Large `.form-control` border radius
$input-border-radius-large:      $border-radius-large !default;
//** Small `.form-control` border radius
$input-border-radius-small:      $border-radius-small !default;

//** Border color for inputs on focus
$input-border-focus:             #66afe9 !default;

//** Placeholder text color
$input-color-placeholder:        #999 !default;

//== Form states and alerts
//
//## Define colors for form feedback states and, by default, alerts.

$state-success-text:             $brand-success !default;
$state-success-bg:               #dff0d8 !default;
$state-success-border:           darken(adjust-hue($state-success-bg, -10), 5%) !default;

$state-info-text:                #31708f !default;
$state-info-bg:                  #d9edf7 !default;
$state-info-border:              darken(adjust-hue($state-info-bg, -10), 7%) !default;

$state-warning-text:             #8a6d3b !default;
$state-warning-bg:               #fcf8e3 !default;
$state-warning-border:           darken(adjust-hue($state-warning-bg, -10), 5%) !default;

$state-danger-text:              #a94442 !default;
$state-danger-bg:                #f2dede !default;
$state-danger-border:            darken(adjust-hue($state-danger-bg, -10), 5%) !default;

//== Tables
//
//## Customizes the `.table` component with basic values, each used across all table variations.

//** Padding for `<th>`s and `<td>`s.
$table-cell-padding:            18px 15px !default;
//** Padding for cells in `.table-condensed`.
$table-condensed-cell-padding:  5px !default;

//** Default background color used for all tables.
$table-bg:                      transparent !default;
//** Background color used for `.table-striped`.
$table-bg-accent:               #f9f9f9 !default;
//** Background color used for `.table-hover`.
$table-bg-hover:                #f5f5f5 !default;
$table-bg-active:               $table-bg-hover !default;

//** Border color for table and cell borders.
$table-border-color:            #ddd !default;

// Panels
$panel-default-heading-bg: #fff;

//== Navbar
//
//##

// Basics of a navbar
$navbar-height:                    80px !default;
$navbar-default-color:             #fff !default;
$navbar-default-bg:                #343942 !default;
$navbar-default-border:            darken($navbar-default-bg, 6.5%) !default;
$navbar-border-radius:             0;

// Navbar links
$navbar-default-link-color:                #fff !default;
$navbar-default-link-hover-color:          #ccc !default;
$navbar-default-link-hover-bg:             transparent !default;
$navbar-default-link-active-color:         #ccc !default;
$navbar-default-link-active-bg:            darken($navbar-default-bg, 6.5%) !default;
$navbar-default-link-disabled-color:       #ccc !default;
$navbar-default-link-disabled-bg:          transparent !default;


//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

$btn-font-weight:                normal !default;

$btn-default-color:              #333 !default;
$btn-default-bg:                 #fff !default;
$btn-default-border:             #ccc !default;

$btn-primary-color:              #fff !default;
$btn-primary-bg:                 $brand-primary !default;
$btn-primary-border:             $brand-primary !default;

$btn-success-color:              #fff !default;
$btn-success-bg:                 $brand-success !default;
$btn-success-border:             $brand-success !default;

$btn-info-color:                 #fff !default;
$btn-info-bg:                    $brand-info !default;
$btn-info-border:                $brand-info !default;

$btn-warning-color:              #fff !default;
$btn-warning-bg:                 $brand-warning !default;
$btn-warning-border:             $brand-warning !default;

$btn-danger-color:               #fff !default;
$btn-danger-bg:                  $brand-danger !default;
$btn-danger-border:              $brand-danger !default;


//== Modals
//
//##

//** Padding applied to the modal body
$modal-inner-padding:         15px !default;

//** Padding applied to the modal title
$modal-title-padding:         15px !default;
//** Modal title line-height
$modal-title-line-height:     $line-height-base !default;

//** Background color of modal content area
$modal-content-bg:                             #fff !default;
//** Modal content border color
$modal-content-border-color:                   rgba(0,0,0,.2) !default;
//** Modal content border color **for IE8**
$modal-content-fallback-border-color:          #999 !default;

//** Modal backdrop background color
$modal-backdrop-bg:           transparentize(#343942, .1) !default;
//** Modal backdrop opacity
$modal-backdrop-opacity:      1 !default;
//** Modal header border color
$modal-header-border-color:   #e5e5e5 !default;
//** Modal footer border color
$modal-footer-border-color:   $modal-header-border-color !default;

$modal-lg:                    900px !default;
$modal-md:                    600px !default;
$modal-sm:                    300px !default;