.navbar.navbar-default {
    border-top: 4px solid $brand-logo;
    border-bottom: 0;
}

.navbar-brand {
    padding: 0;

    > img {
        height: $navbar-height;
        padding: 10px $navbar-padding-horizontal;
    }
}

.navbar-search {
    margin-top: $navbar-padding-horizontal + 8;
}

.navbar-search .select2 {
    min-width: 300px !important;
    max-width: 300px !important;
}