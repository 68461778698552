.panel {
    box-shadow: 0 2px 3px rgba(0,0,0,.2);
}

.panel.panel-default .panel-title {
    background: lighten($gray-base, 98.5%);
}

.panel-table .table-bordered {
    border-style: none;
    margin: 0;
}

.panel-table .table > thead > tr {
    background: $body-bg;
}

.panel-table .table > thead > tr > th {
    border-bottom: 1px solid $table-border-color;
}

.panel-table .table-bordered > thead > tr > th:last-of-type,
.panel-table .table-bordered > tbody > tr > td:last-of-type {
    border-right: 0px;
}

.panel-table .table-bordered > thead > tr > th:first-of-type,
.panel-table .table-bordered > tbody > tr > td:first-of-type {
    border-left: 0px;
}

.panel-table .table-bordered > tbody > tr:last-of-type > td {
    border-bottom: 0;
}