// Custom checkbox and radio CSS
//.radio,
//.radio-inline,
.checkbox,
.checkbox-inline {
    label {
        padding-left: 25px;
    }

    input[type="radio"],
    input[type="checkbox"] {
        margin-left: -25px;
    }
}

input[type="radio"],
.radio input[type="radio"],
.radio-inline input[type="radio"] {
    position: relative;
    margin-top: 6px;
    margin-right: 4px;
    vertical-align: top;
    border: none;
    background-color: transparent;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    &:before,
    &:after {
        content: "";
        display: block;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        @include transition(240ms);
    }

    &:before {
        position: absolute;
        left: 0;
        top: -3px;
        background-color: $brand-primary;
        @include scale(0);
    }

    &:after {
        position: relative;
        top: -3px;
        border: 2px solid $gray-light;
    }

    &:checked:before {
        @include scale(0.5);
    }

    &:disabled:checked:before {
        background-color: $gray-lighter;
    }

    &:checked:after {
        border-color: $brand-primary;
    }

    &:disabled:after,
    &:disabled:checked:after {
        border-color: $gray-lighter;
    }
}

input[type="checkbox"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
    position: relative;
    border: none;
    margin-bottom: -4px;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    &:focus:after {
        border-color: $brand-primary;
    }

    &:after {
        content: "";
        display: block;
        width: 18px;
        height: 18px;
        margin-top: -2px;
        margin-right: 5px;
        border: 2px solid $gray;
        border-radius: 2px;
        @include transition(240ms);
    }

    &:checked:before {
        content: "";
        position: absolute;
        top: 0;
        left: 6px;
        display: table;
        width: 6px;
        height: 12px;
        border: 2px solid #fff;
        border-top-width: 0;
        border-left-width: 0;
        @include rotate(45deg);
    }

    &:checked:after {
        background-color: $brand-primary;
        border-color: $brand-primary;
    }

    &:disabled:after {
        border-color: $gray-light;
    }

    &:disabled:checked:after {
        background-color: $gray-light;
        border-color: transparent;
    }
}

[data-editor].form-control {
    height: auto;
    min-height: $input-height-base * 3;
}